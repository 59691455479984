"use client";

import Image from "next/image";
import Link from "next/link";
import { MyNauiLongArrowRight } from "@/assets/icons/mynaui-long-arrow-right";

// Images des designs de cartes cadeaux
import CasaDelGrey from "/public/images/collections/2022-casa-del-grey/ALEXANDRA-GREY-CASA-DEL-GREY-14.jpg";
import LightGrey from "/public/images/collections/2021-light-grey/ALEXANDRA-GREY-LIGHT-GREY-13.jpg";
import NaturalGrey from "/public/images/collections/2023-natural-grey/ALEXANDRA-GREY-NATURAL-GREY-10.jpg";

export const GiftCardSection = () => {
  const giftCardDesigns = [
    {
      image: CasaDelGrey,
      title: "Casa Del Grey",
      description: "Collection 2022",
    },
    {
      image: LightGrey,
      title: "Light Grey",
      description: "Collection 2021",
    },
    {
      image: NaturalGrey,
      title: "Natural Grey",
      description: "Collection 2023",
    },
  ];

  return (
    <section className="flex w-full flex-col gap-[3rem] px-4 py-20 ph:px-24 md:gap-0 md:px-48 lg:px-72 xl:px-96">
      <div className="flex w-full flex-col justify-between md:flex-row">
        <h2 className="mb-2 font-cormorant text-4xl text-primary ph:text-5xl sm:text-6xl md:mb-12 lg:mb-16 lg:text-7xl">
          CARTES CADEAUX
        </h2>
        <Link
          href="/carte-cadeau"
          className="inline-flex flex-row items-center gap-2 text-sm text-primary transition-colors hover:text-gold xs:ml-1 ph:text-base md:mb-5 md:ml-0 lg:mb-7 xl:mb-8"
        >
          Offrir une carte cadeau
          <MyNauiLongArrowRight className="h-4 w-4 ph:h-6 ph:w-6" />
        </Link>
      </div>

      <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
        {giftCardDesigns.map((design, index) => (
          <Link
            href="/carte-cadeau"
            key={index}
            className="group"
            aria-label={`Offrir une carte cadeau ${design.title}`}
          >
            <div className="flex flex-col gap-4">
              <div className="relative aspect-[3/4] w-full overflow-hidden">
                <div className="absolute inset-0 z-10 bg-black/20 transition-all group-hover:bg-black/30" />
                <Image
                  src={design.image}
                  alt={`Design carte cadeau ${design.title}`}
                  fill
                  sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
                  className="object-cover transition-transform duration-500 ease-out group-hover:grayscale"
                />
                <div className="absolute bottom-0 left-0 right-0 z-20 p-6">
                  <h3 className="text-xl font-medium text-white">
                    {design.title}
                  </h3>
                  <p className="text-sm text-gray-200">{design.description}</p>
                </div>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="mt-12 flex flex-col items-center gap-6 bg-gray-50 p-8 text-center">
        <h3 className="text-2xl font-medium text-gray-900">
          Offrez un moment de beauté unique
        </h3>
        <p className="max-w-2xl text-gray-600">
          Nos cartes cadeaux sont personnalisables et valables dans tous nos
          salons de coiffure, notamment ceux de <strong>Villejuif</strong> et{" "}
          <strong>Ville d’Avray</strong>. Parfaites pour toutes les occasions à
          partir de 50€, elles permettent à vos proches de vivre l’expérience{" "}
          <em>coiffure & visagisme</em> by Alexandra Grey.
        </p>
        <div className="text-center">
          <Link
            href="/carte-cadeau"
            className="group relative inline-block overflow-hidden border border-primary bg-white px-8 py-3 text-base font-medium text-primary transition-colors ph:text-lg"
          >
            <span className="relative z-10 transition-colors group-hover:text-white">
              Créer ma carte cadeau
            </span>
            <div className="absolute bottom-0 left-0 h-full w-0 bg-primary transition-all duration-300 ease-out group-hover:w-full"></div>
          </Link>
        </div>
      </div>
    </section>
  );
};
