"use client";

import { useState, useEffect } from "react";
import hero from "@/assets/images/landing/hero.webp";
import { LIArrowDown } from "@/assets/icons/li-arrow-down";
import { HeroBanner } from "@/components/sections/HeroBanner";

export const HeroSection = () => {
  const [isBannerVisible, setIsBannerVisible] = useState(true);

  const scrollToNosSalons = () => {
    const nosSalonsSection = document.getElementById("nos-salons");
    nosSalonsSection?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const handleScroll = () => {
      const heroSection = document.getElementById("hero-section");
      if (heroSection) {
        const heroBottom = heroSection.getBoundingClientRect().bottom;
        setIsBannerVisible(heroBottom > 0);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <section
      id="hero-section"
      className="relative flex min-h-[100dvh] w-full flex-col items-center justify-center gap-32 overflow-x-hidden bg-cover bg-center bg-no-repeat px-4 ph:px-24 md:px-48 lg:px-72 xl:px-96"
      style={{
        backgroundImage: `linear-gradient(rgba(25, 25, 25, 0.75), rgba(25, 25, 25, 0.75)), url(${hero.src})`,
      }}
    >
      <HeroBanner />
      <div
        className={`flex flex-col items-center justify-center text-center ${isBannerVisible ? "mt-[100px]" : ""}`}
      >
        <span className="text-md mb-2 uppercase tracking-wider text-white ph:mb-4 ph:text-lg">
          3 salons · 1 vision
        </span>

        <h1 className="mb-[-1rem] whitespace-nowrap font-cormorant text-4xl uppercase leading-[0.9] tracking-wider text-white xs:text-5xl ph:mb-[-1.5rem] ph:text-6xl sm:text-7xl md:mb-[-2rem] md:text-8xl lg:text-9xl">
          Coiffure <br /> & Visagiste
        </h1>
        <span className="mb-8 font-pencerio text-3xl text-gold sm:text-4xl md:text-5xl lg:text-6xl">
          Alexandra Grey
        </span>

        <p className="text-md max-w-2xl text-white ph:hidden">
          <strong>Alexandra Grey</strong>, vos salons de coiffure à{" "}
          <strong>Villejuif</strong> et <strong>Ville d&apos;Avray</strong>. Une
          équipe de <em>coiffeurs-visagistes</em> passionnés.
        </p>

        <p className="text-md hidden max-w-2xl text-white ph:block ph:text-lg sm:hidden">
          Le concept <strong>Alexandra Grey</strong> est né d&apos;une approche
          humaine de la coiffure. Présents à <strong>Villejuif</strong> et{" "}
          <strong>Ville d&apos;Avray</strong>, nous mettons notre savoir-faire
          de
          <em> coiffeurs-visagistes</em> au service de votre style.
        </p>

        <p className="text-md hidden max-w-3xl text-white ph:text-lg sm:block">
          Découvrez les <strong>salons de coiffure Alexandra Grey</strong> à{" "}
          <strong>Villejuif</strong> et <strong>Ville d&apos;Avray</strong>.
          Notre équipe de <em>coiffeurs-visagistes experts</em> vous accueille
          dans un cadre raffiné pour sublimer votre style. Spécialistes de la{" "}
          <em>coiffure femme, homme et enfant</em>, nous vous proposons des
          prestations sur-mesure.
        </p>
      </div>
      <div
        onClick={scrollToNosSalons}
        className="flex flex-col items-center justify-center text-white"
      >
        <div className="scroll-down-wrapper flex flex-col items-center justify-center">
          <LIArrowDown className="relative z-10 cursor-pointer" width={38} />
        </div>
      </div>
    </section>
  );
};
